/**
 * Libs (rendo disponibili globalmente)
 */
import Utilities from "./lib/Utilities";
import Form from "./lib/Form";
window.Form = Form;
window.Utilities = Utilities;

var debugMode = false;

/**
 * DOM ready
 */
$(function() {
    /**
     * Delay per resize della larghezza della finestra.
     * Converte eventi multipli di resize in uno solo.
     */
    var resizeTimeout;
    $(window).data("current-width", $(window).width()).on("resize", function() {

        clearTimeout(resizeTimeout);

        resizeTimeout = setTimeout(function() {

            // se è variata la larghezza lancio evento
            if ($(window).width() != $(window).data("current-width")) {

                $(window).data("current-width", $(window).width());

                // evento custom ad uso di altre funzioni
                $(window).trigger("width_resize");

            }

        }, 500);
    });
});


/**
 * Load
 */
$(window).on("load", function() {

    /**
     * Init di barba
     * Deve essere incluso nella blade base!
     * L'evento "loadermanager-page_loaded" viene lanciato quando la coda
     * di LoaderManager è stata svuotata
     */
     $(document).on("loadermanager-page_loaded", function() {
        
        //menu principale
        menuInit();

        //banner newsletter
        bannerNL_init();

        // init barba.js
        barbaInit();
 
        //inizializzo locomotive scroll
        locomotiveInit();

        // rimozione loader
        setTimeout(function() {
            pageEnter();

            $('body').removeClass('loading');

        }, 200);

        // gestione ancore interne 
        updateAnchors();

    });


    /**
     * Fine caricamento
     */
    LoaderManager.eventComplete("site_loading");
    
    
    /**
     * Init di lazysizes
     */
    document.addEventListener('lazyloaded', function(e){
        e.target.classList.remove('loading');
    });
    lazySizes.init();

});



/**
 * init locomotive scroll
 */
 var scrollPos = 0;

 let scroll;
 
 var visualizzaFiltri_h;
 var visualizzaFiltri;
 var direction;
 function locomotiveInit() {
 
    if(document.querySelector('body').classList.contains('disable-scroll')) {
        return;
    }

     let options = {
 
         el: document.querySelector('[data-scroll-container]'),
         smooth: true,
         getSpeed: true,
         getDirection: true,
         
         smartphone: {
            smooth: false
        },

        tablet: {
            smooth: false
        },
         reloadOnContextChange: true,
         firefoxMultiplier: 70,
         resetNativeScroll: true
     }
 
    
    var oldHeight = 0;
    var contentHeight;
    var scrollTimeout;


     scroll = new LocomotiveScroll(options);
 
     scroll.on('scroll', (instance) => {
       
         scrollPos = instance.scroll.y;

         //cambio stato all'header
         if (scrollPos > 55) {
            $('body').addClass('scrolling');
         } else {
             $('body').removeClass('scrolling');
         }
         //visualizza i filtri fixed in alcune pagina (lista prodotti, lista progetti)
         if(visualizzaFiltri && (scrollPos > visualizzaFiltri_h)){
            $('body').addClass('visualizzaFiltri');
         } else {
            $('body').removeClass('visualizzaFiltri');
         }
         direction = instance.direction;
        
        
        //accrocchio per evitare calcolo sbagliato dell'altezza della pagina
        clearTimeout(scrollTimeout);

         scrollTimeout = setTimeout(function() {
 
            contentHeight = Math.round($('.contents').height());
            
            if(debugMode){
                console.log("contentHeight > " + Math.abs(contentHeight));
                console.log("oldHeight > " + Math.abs(oldHeight));
                console.log("------ ");
            }
            //se l'altezza dei contenuti differisce più di 10px aggiorno lo scroll
            if(Math.abs((Math.abs(contentHeight) - Math.abs(oldHeight)))>10 ){
                if(debugMode){console.log('altezza diversa - update scroll ----------------')}
                oldHeight = contentHeight;
                scroll.update();
            }
         }, 50);


        


     })


     setTimeout(() => {
        scroll.on('call', (value, way, obj) => {
                if(debugMode){
                    console.log(way + " | " + obj.id + " | " + direction + " | >>>>" + $('body').attr('data-namespace'));
                }
                if (way === 'enter') {
                    switch (value) {

                        case "header-negative":                        
                        $('body').addClass('negative');
                        break;

                        case "header-positive":                        
                        $('body').removeClass('negative');
                        break;

                        case "autoplayVideo":
                        autoplayVideo();
                        break;

                        case "pageColor":
                        // cambio colore al body (letto da  data-scroll-id )
                        document.querySelector('body').style.backgroundColor = obj.id;

                        break;
                         
                    }
                }

                if($('body').attr('data-namespace')!='prodotti_dettaglio'){
                    if(way === 'exit'){
                        switch (value) {
                        case "header-negative":
                            
                            $('body').removeClass('negative');
                            
                            break;
                        }
                    }
                }

        });
      }, 800);
 
     //aggiorno lo scroll 
     setTimeout(function() {
        if(window.scroll) {
            scroll.update();
        }
     }, 200);
 
     // memorizzo per accesso in altri punti
     window.locoScroll = scroll;
 
     // ad uso di altri script
     $(document).trigger("locomotive-init");

    /**
     * Update locomotive dopo messaggi livewire
     * (per adeguamento contenuti)
    */
    Livewire.hook('message.processed', function (e) {

        // if message has been generated by a form, you might want
        // the user to focus to the first error
        // (insert specific selector)
        let error = e.component.el.querySelector('.uk-form-danger');
        if(error) {
            window.locoScroll.scrollTo(error, {
                offset: 0 
            });
        }

        if(window.locoScroll) {
            window.locoScroll.update();
        }
    });

    // scrollo  a primo errore form anche al load
    let error = document.querySelector('.uk-form-danger');
    if(error) {
        window.locoScroll.scrollTo(error, {
            offset: 0 
        });
    }

 } // locomotiveInit



/**
 * Funzione di uscita pagina
 */
 function pageExit(callback)
 {   
     //console.log("-----page EXIT");
      // porto in primo piano prima di iniziare animazione
      $("#page_loader").css({
          zIndex: 100 // sotto all'header!
      });
      
      //animazione in uscita dalla pagina (il loader entra)     
     $( "#page_loader" ).animate({top:0}, 500, function() {
         // Animation complete
         if (callback && typeof(callback) == 'function') {
             callback();
         }
     });

     //nascondo il cambio lingua
     $( ".palette-languages" ).removeClass('visibile');
      
 }
 
 
/**
 * Funzione di entrata pagina
 */
 function pageEnter(callback, backToList) {
    
    setTimeout(function() { //aggiunto timeout per problemi di selezione elementi jquery

        //rimuovo il bg color della pagina precedente
        $('body').removeAttr('style');
        
        //gestione dell'header in prima battuta (negativo o positivo) poi verrà cambiato dalle section in pagina
        if($('.contents').attr('data-header') == 'negative'){
            $('body').addClass('negative');
        } else {
            $('body').removeClass('negative');
        }
    
        //GALLERY che scorre in automatico
        if($('.swiper-container-free-mode').length){
            var swiperOptions = {
                loop: true,
                autoplay: {
                    delay: 1,
                    disableOnInteraction: false
                },
                slidesPerView: 'auto',
                speed: 10000,
                grabCursor: true,
                mousewheelControl: true,
                keyboardControl: true,
                freeMode: true
            };
            var swiperFreeMode = new Swiper(".swiper-container-free-mode", swiperOptions);
        }


        //GALLERY standard
        if($('.gallery-standard').length){
            //inizializzo 
            setTimeout(function() {
                UIkit.slider('.gallery-standard',{center: true, finite:true});
                scroll.update();

                $('.gallery-standard').addClass('inview');

            }, 500);
        }



        //gestione filtri fixed
        if($('.filtri-fixed').length){
            visualizzaFiltri = true;
            visualizzaFiltri_h = $('.filtri-fixed').attr('data-scroll-h');

            if($('body').width()< 1024){
                visualizzaFiltri_h = 100;
            }
            //inizializzo i dropdown
            UIkit.dropdown('.uk-dropdown',{mode: 'click', pos: 'bottom-justify', animation: 'uk-animation-slide-top-small', duration: 300});

        } else {
            visualizzaFiltri = false;
        }


        //embed video
        manageVideos();

        //fitty (ridimensiona il testo in base al contenitore)
        fitty('.fit', {
            minSize: 12,
            //maxSize: 300,
        });

        //zoom su immagine
        $('a.photoZoom').each(function() {
            var zoomUrl = $(this).attr('data-url-zoom');
            var bgColor = $(this).attr('data-bg-zoom');
            if(bgColor=="" || bgColor==undefined){
                bgColor = '#dad7d0';
            }
                if(zoomUrl){
                    $(this).zoom({
                        url: zoomUrl, 
                        on: 'click',
                        duration: 0,
                        onZoomIn: function(){
                            $(this).addClass('zooming');
                            $(this).css("background-color", bgColor);
                        },
                        onZoomOut: function(){
                            $(this).removeClass('zooming');
                        }
                    });
                }
            
        });

        
        
        if(backToList) {
            
            //se c'è una posizione memorizzata, la ripristino
            window.locoScroll.scrollTo(parseInt(listScrollPosition), {
                duration: 0
            });
        }

    }, 50);



    //animazione in entrata della pagina (uscita del loader)
    $( "#page_loader" ).animate({opacity: "0"}, 1000, function() {

        // Animation complete
        
        // porto sotto per poter interagire con pagina
        $("#page_loader").css({
            zIndex: -1,
            //height:'100%',
            top:'100%',
            opacity:1
        });
        if (callback && typeof(callback) == 'function') {
            callback();
        }
        
    })


    //aggiorno locomotive scroll
    setTimeout(function() {
        if(window.locoScroll) {
            window.locoScroll.update();
        }
    }, 300);

}


function myLeave(data){
    //scrollo in cima               
    scroll.scrollTo(0, {duration:0, disableLerp:true});
    //se serve elimino i modal
    $('.modal_suggestion').remove();
    setTimeout(function() {
        //tolgo la classe scrolling
        $('body').removeClass('scrolling');
    }, 200);
    //chiudo menu
    chiudiMenu(0);
}
function myAfterLeave(data){
    $("body").removeClass (function (index, className) {
        return (className.match (/(^|\s)page-\S+/g) || []).join(' ');
     });
}
function myAfterEnter(data){
    // tracciamento analytics
    if(typeof(gtag) == 'function' && typeof(GA_MEASUREMENT_ID) != 'undefined') {
        gtag('config', GA_MEASUREMENT_ID, {'page_path': window.location.pathname});
    }
}

var listScrollPosition;
/**
 * Gestione caricamento asincrono
 */
function barbaInit()
{   

    // controllo disponibilità barba
     if(typeof(barba) === "undefined") {
         return console.log("barba non disponibile");
     }
 
     
 
     barba.init({
         timeout: 5000,
         debug: window.DEBUG_MODE || false,
         transitions: [{
             name: 'default-transition',
             beforeLeave(data) {

                //pagina lista prodotti, resetto la posizione scroll 
                listScrollPosition=0;
                const done = this.async();
                return pageExit(function() {
                    return done();
                });
 
             },
             leave(data) { myLeave(data); },
             afterLeave(data) {myAfterLeave(data); },
             beforeEnter(data) { },
             enter(data) { },
             afterEnter(data) {
                myAfterEnter(data);

                updateDom(data, function() {
                    pageEnter();
                });
 
             }
         },{
            name: 'toDetail',
            from: { namespace: [ 'prodotti_categoria', 'kaleido-compositions', 'risultatoRicerca' ]},
            to: { namespace: [ 'prodotti_dettaglio', 'kaleido-composition-detail' ] },
            beforeLeave(data) { 
                //pagina lista prodotti, salvo la posizione scroll 
                listScrollPosition=scrollPos;
                //
                const done = this.async();
                return pageExit(function() {
                    return done();
                });
             },
            leave(data) { myLeave(data); },
            afterLeave(data) {myAfterLeave(data); },
            beforeEnter(data) { },
            enter(data) { },
            afterEnter(data) {
                myAfterEnter(data);
                
                updateDom(data, function() {
                    pageEnter();
                });
            }
        },{
            name: 'backToList',
            from: { namespace: [ 'prodotti_dettaglio', 'kaleido-composition-detail' ]},
            to: { namespace: [ 'prodotti_categoria', 'kaleido-compositions', 'risultatoRicerca' ] },
            beforeLeave(data) { 
                const done = this.async();
                return pageExit(function() {
                    return done();
                });
             },
            leave(data) { myLeave(data); },
            afterLeave(data) {myAfterLeave(data); },
            beforeEnter(data) { },
            enter(data) { },
            afterEnter(data) {
                myAfterEnter(data);
                
                updateDom(data, function() {
                    pageEnter(null, true);
                });
            }
        }],
         views: [
            {
                
                //HOMEPAGE
                namespace : 'homepage',
                afterEnter(data) {

                    //inizializzo swiper HERO
                    var swiper = new Swiper(".swiper-hero", {
                        /*
                        effect: "fade", // Imposta l'effetto 'fade'
                        fadeEffect: {
                          crossFade: true, // Opzione per un fade più fluido tra le slide
                        },
                        */
                       speed: 1500, 
                        
                       parallax: true,
                       mousewheel:false,
                       loop:true,
                       keyboard:true,
                       slideToClickedSlide:true,
                       
                       navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      },
                      pagination: {
                        el: ".swiper-pagination",
                        clickable: true,
                      },
                   });
                     
                }
            }, {
                //newsletter
                namespace : 'newsletter',
                beforeEnter(data) {
                    bannerNL_nascondi();
                }
            }, {
                //prodotti_categoria
                namespace : 'prodotti_categoria',                
                afterEnter(data) {
                    $( '.productSearchBtn').on('click', this, function(){
                        apriRicerca();
                    });

                    // "carica altri prodotti"
                    $('a.more').on('click', function(e) {
                        e.preventDefault();
                        let link = $(this);
                        $.get(link.attr('href')+'&js=1', function(response) {
                            // aggiungo contenuto
                            $('.grid.prodotti').append(response.html);
            
                            // aggionro link
                            if(response.nextLink)
                            {
                                link.attr('href', response.nextLink);
                            }
                            else
                            {
                                link.hide();
                            }

                            //Livewire.rescan();
            
                        });
                    });

                }
            } , {
                namespace : 'kaleido',
                beforeEnter(data) {
                    //playPausa video
                    initVideoPlayPause();
                },
                afterEnter(data) {
                    //effetto caleidoscopio
                    initKaleido();
                }
            }, {
                namespace : 'kaleido-elements',
                afterEnter(data) {
                    //effetto caleidoscopio
                    initKaleidoElement();
                }
            },
            {
                namespace: 'download_area',
                afterEnter(data) {
                    //init download area
                    downloadArea();
                }
            }

         ],
         requestError: (trigger, action, url, response) => {
             
             // forzo a seguire le url 404 per visione del messaggio
             if(action == 'click' && response.status == 404) {
                 window.location.href = url;
                 return false;
             }
 
            //console.log(trigger, action, url, response);
 
         },
         preventRunning : true
     });
    

} // barba init


function initKaleido(){
    //effetto caleidoscopio - su desktop
    var desk = ($(window).width() > 944);
    if(desk){
        const segmentCount = 8;
        const wrapper = document.querySelector("#caleidoscopio");
        let images = createSegments(segmentCount, wrapper);

        // Generate the segments and image wrappers with js
        // Could be done on the BE if that's your thing
        function createSegments(count, wrapper) {
            
            let images = [];
            
            for (let i = 0; i < count; i++) {
                const element = document.createElement("div");
                element.classList.add("segment");

                const image = document.createElement("div");
                image.classList.add("image");

                element.appendChild(image);
                images.push(image);
                wrapper.appendChild(element);
            }

            return images;
            
        }

        function handleMouseMove(e) {
            const nx = e.pageX;
            const ny = -e.pageY;
            images.forEach((image) => {
                image.style.backgroundPosition = [ny + "px"].join(" ");
            });
        }

        if (window.matchMedia("(prefers-reduced-motion: no-preference)")) {
            wrapper.addEventListener("mousemove", handleMouseMove);
        }

    } 
}

function initKaleidoElement(){
    
    if(!checkTouch()){
        $(document).on('mouseenter', '.elements .item', function(event){
            var slider = $( this ).find( '.slider' );
            UIkit.slideshow(slider).startAutoplay();
            UIkit.slideshow(slider).show(2);
        }).on('mouseleave', '.elements .item', function(event){
            var slider = $( this ).find( '.slider' );
            UIkit.slideshow(slider).stopAutoplay();
            UIkit.slideshow(slider).show(0);
        });
    }
    
}

 function updateAnchors(){
    //sostituisce le ancore in pagina con funzione locomotiveScroll
    $(document).on('click', 'a.anchor', function(event){ 
            var anchor = $( this ).attr( 'href' );
            var myOffset = $( this ).attr( 'data-offset' );
            if(myOffset == null){
                myOffset = -50;
            }
            if(anchor != '' && anchor.startsWith( '#')){
                event.preventDefault();
                scroll.scrollTo(anchor, {duration:1000, offset:myOffset});
            }
    });
}

function autoplayVideo(){    
    const videoElement = document.querySelector('video.videoAutoplay');
    if(videoElement) {
        videoElement.play(); 
    } 
}

function initVideoPlayPause(){

    var obj;
    if ($(window).width() < 700) {
        obj = 'video.videoAutoplay.mobile';
        $('video.videoAutoplay.desk').remove();
    } else {
        obj = 'video.videoAutoplay.desk';
        $('video.videoAutoplay.mobile').remove();
    }

    const videoElement = document.querySelector(obj);
    const playPauseButton = document.querySelector('.playPauseButtons');
    const reloadButton = document.querySelector('.reloadButton');


    if(videoElement) {

        playPauseButton.addEventListener('click', () => {
            playPauseButton.classList.toggle('playing');
            if (playPauseButton.classList.contains('playing')) {
                videoElement.play();
            }
            else {
                videoElement.pause();
            }
        });
        videoElement.addEventListener('ended', () => {
            playPauseButton.classList.remove('playing');
        });
        //reload
        reloadButton.addEventListener('click', () => {
            playPauseButton.classList.add('playing');
            videoElement.currentTime = 0;
            videoElement.play();
        });
    }
}

function menuInit(){
    $( 'header li.menuToggler a').on('click', this, function(){
        if($('body').hasClass('menu')){
            chiudiMenu(500);
        } else {
            apriMenu(0);
        }
    })
    //ricerca prodotto
    $( 'header li.search a').on('click', this, function(){
        if($('body').hasClass('search')){            
            chiudiRicerca();
        } else {
            apriRicerca();
        }
    })

    //gestione click sulle 2 collezioni in mobile
    $( '.mainMenu .linkCategoria' ).on('click', function(e){
        e.preventDefault();
        var idMenu = $(this).attr('data-rel');
        $('#'+idMenu).addClass('visibile');
        $('#wrapper-'+idMenu + ' > div').addClass('cliccato');
    })
}

function chiudiMenu(t){
    $('body').removeClass('menu');
    $('.mainMenu').fadeOut(t, function(){
        //se serve, nascondo anche il sottomenu categorie (per il mobile)
        $( this ).find('.visibile').removeClass('visibile');
        $( this ).find('.cliccato').removeClass('cliccato');
    });
    chiudiRicerca();
}
function apriMenu(d){
    //pagina lista prodotti, resetto la posizione scroll 
    listScrollPosition=0;
    $('body').addClass('menu');
    $('.mainMenu').delay(d).fadeIn(500, function(){});
}

function apriRicerca(){
    apriMenu(250);
    $('body').addClass('search');
    $('.productSearch').fadeIn(500, function(){
        $('#productSearch').focus();
    });

    
}

function chiudiRicerca(){
    $('body').removeClass('search');
    $('.productSearch').fadeOut(500, function(){});
}



/**
 * Gestione play/pause sui video embed dei widget
 */
 function manageVideos()
 {
 
     // carica api iframe, se il relativo id non è già presente
     if(!$('#www-widgetapi-script').length) {
         var tag = document.createElement('script');
         tag.src = "https://www.youtube.com/iframe_api";
         var firstScriptTag = document.getElementsByTagName('script')[0];
         firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
     // se lo script è già presente, chiamo direttamente la funzione di callback
     } else {
         onYouTubeIframeAPIReady();
     }
 
     // riferimento al callback del caricamento api
     // per evitare rinominazione della funzione "onYouTubeIframeAPIReady"
     // in fase di compilazione
     window.onYouTubeIframeAPIReady = onYTready;
     function onYTready() {
 
         // seleziono i player interni ai widget
         let videoPlayers = $('.embedYoutube div.player');
         if(!videoPlayers.length) {
             return;
         }
     
         videoPlayers.each(function() {
             
             let videoId = $(this).attr('data-videoId');
 
             // gestione del play sul div che contiene il player
             let playArea =  $(this).parent('div');
             let videoStop = true;
             playArea.on('click', function() {
                 
                 if(videoStop){
                     //il video è in stop, lo faccio partire
                     player.playVideo();
                     playArea.addClass('playing');
                 } else {
                     player.stopVideo();
                     playArea.removeClass('playing');
                 }
                 videoStop = !videoStop;
             });
 
             // crea player su questo widget
             let player = new YT.Player($(this)[0], {
                 height: '360',
                 width: '640',
                 videoId: videoId,
                 playerVars: {
                     'autoplay': 0,
                     'controls': 1
                 },
                 events: {
                     'onStateChange': function(event) {
                         
                         // quando il video si ferma, rimuovo la classe playing dal wrapper
                         // e modifico il cursore se si trova sopra il video che si è appena fermato
                         if(event.data == 0 || event.data == 2) {
                            videoStop = true;
                            playArea.removeClass('playing');
                         }
                     }
                 }
             });
         });
     }
}


function bannerNL_init(){
    
    if(window.sessionStorage.getItem('bannerNL_view')) {
        return;
    }

    $('.bannerNewsletter .close').on('click', function(e) {
        e.preventDefault();
        bannerNL_nascondi();
     });

     //dopo 3 secondi visualizzo (su risoluzioni >960)
    if (!checkMobile()) {
        setTimeout(function() {
            $('.bannerNewsletter').addClass('visualizza');
        }, 3000);
    } 

    

}
function bannerNL_nascondi(){
    $('.bannerNewsletter').addClass('nascondi');
    window.sessionStorage.setItem('bannerNL_view', true);
}


function checkMobile() {
    if ($(window).width() > 959) {
        return false;
    } else {
        return true;
    }
}

function checkTouch() {
    if ($('html').hasClass('touch')) {
        return true;
    } else {
        return false;
    }
}

/**
 * Aggiorna il dom.
 * Sono possibili diverse strategie (combinarle a bisogno):
 * 1. sostituire in toto l'head (questo scatena anche anche esecuzione degli script, anche inline)
 * 2. sostituire tag specifici nell'head
 * 3. - inserire script/style specifici per la nuova pagina nel container di quest'ultima.
 *    - caricare gli script in ajax, mentre eventuali tag <style> presenti nel container verranno automaticamente valutati dal browser
 * 
 * Con gli stessi sistemi si possono aggiornare elementi al di fuori del container di barba
 */
function updateDom(data, callback) {

    var html = data.next.html;
    var newHeadHtml = html.match( /<head[\s\S]*?>[\s\S]*?<\/head>/gi )[0];

    // true mantiente gli script
    var $newPageHead = $.parseHTML(newHeadHtml, document, true);

    /**
     * Aggiorno namespace di body 
     */
    $("body").attr("data-namespace", data.next.namespace);


    /**
     * Aggiorno elemento attivo menu principale
     */
    $("#page_header-mainmenu .uk-active").removeClass("uk-active");
    $('#page_header-mainmenu [data-namespace="'+data.next.namespace+'"]').addClass("uk-active");
    
    
    /**
     * aggiorno html altri elementi
     * In ogni elemento dell'array inserire:
     * - o il selettore completo
     * - o il selettore + l'attributo che si vuole rimpiazzare
     */
    [
        'head',
        '#page_header-mainmenu',
        ['#page_header .logo a', 'href'],
        '#page_footer .info_menu',
        '.iconMenu li.language'
    ].forEach((selector) => {

        let realSelector = Array.isArray(selector) ? selector[0] : selector;
        let attribute = Array.isArray(selector) ? selector[1] : null;

        // recupero elemento nel nuovo html in arrivo
        let el = $(realSelector, $(data.next.html));

        // no lambda qui!
        $(realSelector).each(function() {
            if(attribute !== null) {
                // rimpiazzo attributo
                $(this).attr(attribute, el.attr(attribute));
            } else {
                // rimpiazzo contenuto html nella pagina
                $(this).html(el.html());
            }
        });

    });


    /**
     * 1. aggiornamento in toto dell'head
     */
    //$("head").html(newHeadHtml);

    
    /**
     * 2. aggiornamento di script specifici nell'head
     */
    $newPageHead = $( '<head />' ).html($newPageHead);
    var headTags = [
        "meta[name='keywords']",
        "meta[name='description']",
        "meta[property^='og']",
        "meta[name^='twitter']",
        "meta[itemprop]",
        "link[itemprop]",
        "link[rel='prev']",
        "link[rel='next']",
        "link[rel='canonical']",
        "link[rel='alternate']"
    ].join( ',' );
    
    $( 'head' ).find( headTags ).remove();
    
    $newPageHead.find( headTags ).appendTo( 'head' );
    /**
     * 2. // fine
     */


    /**
     * Caricamento script
     */
     $(data.next.container).find('script').each(function (i, script) {
         var $script = $(script);

        // script linkato
        if($script.attr('src')) {
            $.getScript($script.attr('src'));
        // script inline
        } else {
            eval(script.innerHTML);
        }
        
    });


    /**
     * Rescan degli elementi livewire
     * per far funzionare gli eventi click,etc..
     * dopo transizione di barba
     */
    //Livewire.rescan();


    /**
     * 3. // fine
     */

    if(callback && typeof(callback) == 'function') {
        return callback();
    }

    return true;
    
}

/**
 * Gestione favoriti
 */
(function favorites()
{

    if($('body').attr('data-namespace') != 'favorites') {
        return;
    }

    
    Livewire.on('favorites.removed', id => {
        
        let item = $('[data-id="'+id+'"]').parents('.anteprima-prodotto-wrapper');

        item.fadeOut(function() {
            
            item.remove();

            if(!$('#list .anteprima-prodotto-wrapper').length) {
                window.location.reload();
            }
        });
    });

})();


/**
 * Gestione icona favoriti nel menu.
 * Rimane in ascolto degli eventi lanciati da app/Http/Livewire/FavoriteToggler
 */
(function favoritesIconMenu () {
    
    ['favorites.removed', 'favorites.added'].forEach(function(eventName) {
        window.addEventListener(eventName, event => {
            if(event.detail.count == 0) {
                document.querySelector('.iconMenu li.favorites').classList.remove('visibile');
            } else {
                document.querySelector('.iconMenu li.favorites').classList.add('visibile');
            }
        });
    })
})();


/**
 * Gestione popup download "loggati per scaricare i file"
 */
(function downloadCtaLogin() {

    // chiude popup al click su "accedi"
    $(document).on('click', '.modal-login-cta .link', function() {
        UIkit.modal('.modal-login-cta').hide();
        $('.modal-login-cta').remove();
    });
})();


/*
 * Pagina "Area download".
 * Gestione refresh locomotive scroll dopo accordion
 * delle categorie.
 */
function downloadArea(data) {
    // update locomotive dopo accordion
    $('.download-area-list-container').on('shown hidden', 'ul.uk-accordion', function(e) {
        if(window.locoScroll) {
            window.locoScroll.update();
        }
    });
}


/**
 * delay di esecuzione per una funzione
 */
const delay = (function(){
    var timer = 0;
    return function(callback, ms){
      clearTimeout (timer);
      timer = setTimeout(callback, ms);
    };
})();
