/**
 * Ridimensiona un iframe in maniera proporzionale 
 * in base al proprio contenitore
 *
 * @param force se true forza anche se larghezza è minore di quella del contenitore
 */

 class Utilities
 { 
 
     iframeAutoResize($_iframe, force) {
 
         var force = force || false;
 
         var currentWidth = $_iframe.width();
         var currentHeight = $_iframe.height();
 
         var $_target = $_iframe.parent();
 
         var newWidth = $_target.width();
 
         // mi fermo se il contenitore è più grande e non ho forzatura
         if (newWidth > currentWidth && force == false) {return;}
 
         var newHeight = newWidth / currentWidth * currentHeight;
 
         $_iframe.attr({
             width: newWidth
             ,height: newHeight
         });
 
     }
 
 
 
 }
 
 export default Utilities;