/**
 * FUNZIONI PER INVIO FORM
*/
class Form {


    constructor(selector)
    {
        this.el = $(selector);
    }

    bindSubmit()
    {
        $(document).on("submit", this.el, function (e) {
            
            var $_form = $(this);


            // Opzioni impostate attraverso gli attributi "data"
            var opts = {
                submit_type :       $_form.data("submit_type") ? $_form.data("submit_type") : 'normal'          // ajax / normal
                ,callback :         $_form.data("callback") ? $_form.data("callback") : null                    // callback a cui passare il responso
                ,response_type :    $_form.data("response_type") ? $_form.data("response_type") : 'inline'      // popup / inline
            };
            
            // Preloader
            LoaderManager.start();


            // Se è form ajax lo fermo e lo invio in ajax altrimenti non faccio nulla!
            if (opts.submit_type == 'ajax') {
                
                e.preventDefault();
                
                var data = $_form.serialize();
                var action_url = $_form.attr("action");

                $.ajax ({
                    url: action_url,
                    type: 'POST',
                    dataType: 'json',
                    data: data,
                    beforeSend: function () {
                        if ($_form.contents(".response").length) {$_form.contents(".response").remove();}
                        $_form.addClass("loading");
                        
                        $("<div uk-spinner></div>").appendTo($_form);
                    },
                    success: function(response){

                        // Callback custom
                        if (opts.callback) {
                            var fn = window[opts.callback];
                            if (typeof(fn) == 'function') {fn($_form, response);};
                        }

                        LoaderManager.stop();
                        
                        // SE OK E HO input redirect nella risposta reindirizzo al suo valore
                        // (può anche essere usato per ricaricare la pagina stessa)
                        if (response.success) {
                            if (typeof(response.redirect) != 'undefined' && response.redirect) {

                                pageExit(function() {
                                    window.location=response.redirect;
                                });

                            } else {
                                
                                if (opts.response_type == 'inline') {
                                    setTimeout(function () {
                                        $_form.find(".placeholder").removeClass("discard");
                                        $_form.find(".user_input").val("");
                                        $_form.find('input[type="checkbox"]').prop("checked",false);
                                    }, 3000);
                                } 
                                if (opts.response_type == 'popup') {
                                    $(document).on("form_popup_closed", function () {
                                        $_form.find(".placeholder").removeClass("discard");
                                        $_form.find(".user_input").val("");
                                        $_form.find('input[type="checkbox"]').prop("checked",false);
                                    });
                                }

                            }


                        }
                        
                    },
                    // arrivo qui anche per errori di validazione
                    error: function (a,b) {
                        LoaderManager.stop();
                        
                        var response = a.responseJSON;

                        if(response.errors) {

                            var msg = [];

                            // raccolgo messaggi di errore, evidenzio i campi e costruisco
                            // messaggio unico per modal
                            $.each(response.errors, function(i) {

                                var $f = $_form.find('[name="'+i+'"]');
                                $f.addClass("uk-form-danger");
                                $f.siblings("label").addClass("uk-form-danger");
                                // concateno messagio specifico del campo
                                msg.push(response.errors[i].join(""));
                            });
                            var $f = $_form.find("input.uk-form-danger ").first();

                            
                            //UserDialog.error('<strong>Attenzione</strong><br> '+msg.join("<br>"), 0);
                            UIkit.modal.alert(msg.join("<br>"));

                            if ($f.length) {$("html").animate({scrollTop: $f.offset().top - 150}, 200);}

                        } else {
                            UIkit.modal.alert("Si è verificato un errore.");
                        }
                    },
                    complete: function(){
                        $_form.removeClass("loading");
                        $_form.find("[uk-spinner]").remove();
                    }
            
                });
            }
            
        }); 

    }

    placeholders(selector) {

        this.el.find(selector).each(function() {

            var $_pl = $(this);

            $_pl.on("click", function() {
                $_pl.siblings('input,textarea').trigger("focus");
            });

            $_pl.siblings('input,textarea')
                .on("focus", function () {
                    $(this).addClass("focused");
                    $_pl.addClass("discard");
                })
                .on("blur", function () {
                    if (!$(this).val()) { $_pl.removeClass("discard"); $(this).removeClass("focused");}
                })
                .each(function() {
                    if ($(this).val() || $(this).text()) {
                        $_pl.addClass("discard");
                        $(this).addClass("focused");
                    }
                })
        });
    }

}

export default Form;